@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400&display=swap");
* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

body {
  background-color: #222529;
  color: #ffffff;
  font-family: "Tajawal", sans-serif;
}

.auth {
  max-width: 450px;
}

.auth .logoPic {
  width: 100%;
}

.auth button {
  width: 100%;
  border: 0;
  padding: 7px;
  background: #f91c1c;
  color: #ffffff;
  margin: 10px 0;
  -webkit-box-shadow: 0 0 14px #000000;
  box-shadow: 0 0 14px #000000;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.auth button:hover {
  -webkit-box-shadow: 0 5px 17px #383838;
  box-shadow: 0 5px 17px #383838;
}

.uploadPage h1 {
  margin: 50px;
  margin-top: 25px;
}

.uploadPage .uploadZone {
  position: relative;
  border: 1px dashed gainsboro;
  margin: 50px;
  padding: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.uploadPage .uploadZone .progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 10px;
  background: #f91c1c;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.uploadPage .uploadZone img {
  width: 70px;
}

.uploadPage .uploadZone button {
  margin-top: 20px;
  margin-bottom: 7px;
  padding: 12px 15px;
  background: red;
  border: 0;
  outline: 0;
  border-radius: 3px;
  color: white;
  cursor: pointer;
}

.uploadPage .uploadZone .uploadResult {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 15px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.uploadPage .uploadZone .uploadResult p {
  width: 100%;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.uploadPage .uploadZone .uploadResult img {
  max-height: 50px;
  width: 50px;
  margin-right: 15px;
}

.error {
  margin: 10px 0;
  background: #ffebf0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 7px;
  color: #f91c1c;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
  -webkit-animation: fadeIn 0.7s forwards;
  animation: fadeIn 0.7s forwards;
}

.error p {
  margin-left: 7px;
  font-weight: 700;
}

.error svg {
  fill: none;
  stroke: #f91c1c;
}

.noteContainer {
  background: #00000075;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  opacity: 0;
  -webkit-animation: fadeIn 0.5s forwards;
  animation: fadeIn 0.5s forwards;
}

.noteContainer .note {
  min-width: 250px;
  background: white;
  overflow: hidden;
  color: black;
  position: absolute;
  top: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-150px);
  transform: translateX(-50%) translateY(-150px);
  opacity: 0;
  border-radius: 5px;
  text-align: center;
  -webkit-box-shadow: 0 0 14px #fffefe61;
  box-shadow: 0 0 14px #fffefe61;
  -webkit-animation: slideIn 0.5s 0.3s forwards;
  animation: slideIn 0.5s 0.3s forwards;
}

.noteContainer .note .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 12px;
  border-bottom: 1px solid gainsboro;
  color: white;
}

.noteContainer .note .header h3 {
  text-transform: capitalize;
  letter-spacing: 1px;
}

.noteContainer .note .header button {
  height: 30px;
  width: 30px;
  background: none;
  border: 0;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.noteContainer .note .header button svg {
  width: 20px;
  height: 20px;
  fill: white;
}

.noteContainer .note .body {
  padding: 22px 40px;
}

.noteContainer .note .body a {
  border: 0;
  text-decoration: none;
  background: #f91c1c;
  padding: 7px 12px;
  display: inline-block;
  margin-top: 12px;
  color: white;
  border-radius: 3px;
  font-weight: 700;
  letter-spacing: 1px;
}

.imageList {
  margin: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.imageList .imageContainer {
  width: 200px;
  background: #121212;
  height: 250px;
  margin: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.imageList .imageContainer svg {
  fill: white;
  height: 20px;
  margin: 5px;
  cursor: pointer;
}

.imageList .imageContainer .img {
  position: relative;
  max-width: 80%;
  width: auto;
  max-height: 120px;
  min-width: 100%;
  height: auto;
  margin: auto;
}

.imageList .imageContainer .img .copy {
  position: absolute;
  opacity: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 150px;
  background: #0000005e;
  color: white;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-animation: fade 0.3s forwards;
  animation: fade 0.3s forwards;
}

.imageList .imageContainer .img .copy svg {
  margin: 25px;
}

.imageList .imageContainer .img img {
  max-width: 100%;
  max-height: 100%;
}

.imageList .imageContainer .footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #202020;
  padding: 10px 20px;
  margin: 10px;
  margin-bottom: -15px;
}

.imageList .imageContainer p {
  overflow: hidden;
  width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px;
  background-color: #121212;
  -webkit-box-shadow: 0 4px 14px #121212;
  box-shadow: 0 4px 14px #121212;
  z-index: 1;
}

.nav .menuToggle {
  display: none;
}

.nav img {
  height: 100%;
  max-width: 150px;
}

.nav .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav .links a {
  position: relative;
  margin: 0 15px;
  color: #ffffff;
  padding: 7px 5px;
}

.nav .links a:hover::before {
  width: 100%;
}

.nav .links a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 3px;
  width: 0;
  background-color: #f91c1c;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 100vw;
}

.nav .links .activeLink::before {
  width: 100%;
}

.nav .links button {
  margin: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.nav .links button:hover {
  color: #f91c1c;
}

.nav .links button:hover svg {
  fill: #f91c1c;
}

.nav .links button svg {
  width: 25px;
  fill: #ffffff;
  margin: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.sessions {
  margin: 50px;
}

.sessions .sessionsMainContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.sessions .sessionsMainContainer .filters {
  min-width: 200px;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.sessions .status {
  margin: 20px 0;
}

.sessions .status .statusContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 10px 0;
}

.sessions .status .statusContainer label {
  margin-left: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sessions .status .statusContainer label input {
  all: unset;
  position: relative;
}

.sessions .status .statusContainer label input::before,
.sessions .status .statusContainer label input::after {
  content: "";
  position: absolute;
  left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 4px;
}

.sessions .status .statusContainer label input:checked::before {
  background-color: #f91c1c;
}

.sessions .status .statusContainer label input:checked::after {
  height: 10px;
  width: 10px;
  left: -18px;
  background-color: #ffffff;
  -webkit-clip-path: polygon(
    14% 44%,
    0 65%,
    50% 100%,
    100% 16%,
    80% 0%,
    43% 62%
  );
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.sessions .status .statusContainer label .radioInput::before,
.sessions .status .statusContainer label .radioInput::after {
  border-radius: 100%;
  -webkit-clip-path: unset;
  clip-path: unset;
  background-color: #ffffff;
}

.sessions .status .statusContainer label .radioInput:checked::before {
  background-color: #f91c1c;
}

.sessions .status .statusContainer label .radioInput:checked::after {
  background-color: #ffffff;
  -webkit-clip-path: unset;
  clip-path: unset;
}

.sessionsHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 50px;
}

.sessionsHeader .search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 3px;
}

.sessionsHeader .search input {
  all: unset;
  padding: 0 7px;
  color: #222529;
  min-width: 300px;
}

.sessionsTable {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
}

.sessionsTable thead {
  background-color: #f91c1c;
  height: 50px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sessionsTable tbody tr {
  height: 45px;
  border-bottom: 1px solid #ffffff38;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.sessionsTable tbody tr:hover {
  background-color: #3e4148;
}

.sessionsTable tbody tr svg {
  cursor: pointer;
  height: 20px;
  fill: white;
  margin: 0 10px;
}

.sessionsTable span {
  margin: 10px;
}

.addSession {
  position: fixed;
  height: 50px;
  width: 50px;
  right: 25px;
  bottom: 25px;
  background: #f91c1c;
  border-radius: 100vw;
  -webkit-box-shadow: 0 0 14px black;
  box-shadow: 0 0 14px black;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.addSession:hover {
  -webkit-box-shadow: 0 0 14px #7a7a7a;
  box-shadow: 0 0 14px #7a7a7a;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.addSession::after,
.addSession::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  height: 3px;
  width: 25px;
  background: white;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 100vw;
}

.addSession::before {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}

.switcher {
  height: 25px;
  width: 60px;
  background: red;
  border-radius: 100vw;
  position: relative;
}

.switcher::before {
  content: "";
  height: 23px;
  width: 23px;
  position: absolute;
  left: 1px;
  top: 1px;
  background-color: blue;
  border-radius: 100vw;
}

.add {
  all: unset;
  color: #f91c1c;
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 18px;
  position: relative;
  cursor: pointer;
  margin-left: 25px;
}

.add span {
  height: 20px;
  width: 20px;
  border: 2px solid #f91c1c;
  position: absolute;
  left: -25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 100vw;
  position: relative;
}

.add span::before,
.add span::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 1px;
  width: 10px;
  background-color: #f91c1c;
}

.add span::after {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  background: #0000004a;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.popup .container {
  background: white;
  padding: 25px;
  border-radius: 3px;
}

.popup .container input {
  border: 0;
  outline: 0;
  border-bottom: 2px solid;
  padding: 12px 0;
  font-size: 16px;
}

.popup .container button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #f91c1c;
  color: white;
  border: 0;
  padding: 7px 12px;
  margin-top: 15px;
  border-radius: 3px;
  cursor: pointer;
}

.popup .container .cancel {
  background: transparent;
  color: black;
  margin: 0 10px;
}

.customSelect {
  position: relative;
}

.customSelect select {
  height: 40px;
  padding: 12px;
  width: 100%;
  outline: 0;
  border: 0;
  text-transform: capitalize;
}

.inpCont {
  margin: 0;
  margin-bottom: 15;
}

.buttons {
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.button {
  background-color: #222529;
  padding: 7px 12px;
  border-radius: 4px;
  font-weight: 700;
  margin: 0px 10px;
  cursor: pointer;
  min-width: 110px;
  border: 2px solid #f91c1c;
  color: white;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.filled {
  background: #f91c1c;
}

.flexElm {
  width: 30%;
  margin: 15px;
}

.paymentLoading {
  margin: 150px auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #121212;
  padding: 35px 75px;
  color: white;
  position: relative;
  overflow: hidden;
}

.paymentLoading::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 25px;
  background-color: #f91c1c;
  -webkit-animation: slide 1s infinite linear;
  animation: slide 1s infinite linear;
}

.paymentLoading::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -25px;
  height: 5px;
  width: 25px;
  background-color: #ffffff;
  -webkit-animation: slide 1s 0.5s infinite linear;
  animation: slide 1s 0.5s infinite linear;
}

.containerLoading {
  position: relative;
  overflow-x: hidden;
}

.containerLoading::before,
.containerLoading::after {
  position: absolute;
  content: "";
  left: -150px;
  top: 0;
  background-color: #f91c1c;
  height: 5px;
  width: 150px;
  animation: slide 1s infinite alternate-reverse linear;
  z-index: 9;
}

.containerLoading::after {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.404);
  -webkit-animation: none;
  animation: none;
}

.encodersList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.encodersList__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(50% - 20px);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px solid #dcdcdc6b;
  padding: 12px 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.encodersList__item:hover {
  background-color: rgba(255, 255, 255, 0.158);
}

.encodersList__item .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.encodersList__item .flex svg {
  width: 25px;
  height: 25px;
  margin: 5px;
  margin-right: 15px;
  stroke: white;
  stroke-width: 20px;
}

.encodersList__item .edit .react-switch-bg {
  margin-right: 25px !important;
}

.encodersList__item .edit svg {
  width: 30px;
  height: 30px;
  fill: #ffffff;
}

.encodersList__item p {
  padding-top: 5px;
}

.dividerHeader {
  width: 97%;
  margin: auto;
  margin-bottom: -20px;
  padding: 15px 0;
  border-top: 3px solid grey;
}

.selectLoader {
  position: absolute;
  top: 0;
  right: 0;
  background: #000000cf;
  border: 1px solid #bcbdbe;
  height: 100%;
  width: 40px;
  padding: 10px;
}

.logs {
  height: calc(100vh - 520px);
  min-height: 240px;
  min-width: 100%;
  background: white;
  margin: 15px 0;
  color: black;
  padding: 12px;
  line-height: 40px;
  font-family: monospace;
  overflow-y: scroll;
}

.encoderStatus {
  margin: 10px 0;
  background: white;
  padding: 12px;
  color: black;
  font-family: monospace;
}

.filledInput::before {
  display: none;
}

.filledInput input {
  border: 3px solid #f91c1c !important;
}

@-webkit-keyframes fadeIn {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes fade {
  to {
    opacity: 1;
  }
}

@keyframes fade {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes slideIn {
  to {
    opacity: 1;
    -webkit-transform: translateX(-50%) translateY(0);
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes slideIn {
  to {
    opacity: 1;
    -webkit-transform: translateX(-50%) translateY(0);
    transform: translateX(-50%) translateY(0);
  }
}

@-webkit-keyframes slide {
  to {
    left: calc(100% - 25px);
  }
}

@keyframes slide {
  to {
    left: calc(100% - 25px);
  }
}

.customInput {
  margin: 0;
	margin-bottom: 8px;
  color: white;
  position: relative;
  width: auto;
}

.customInput:focus-within::before {
  width: 100%;
}

.customInput p {
  font-weight: 100;
  margin-bottom: 7px;
  text-transform: capitalize;
}

.customInput .input {
  padding: 6px 12px;
  outline: 0;
  border: 0;
  width: 100%;
  height: 42px;
}

.customInput input:disabled {
  background: #121212;
  color: #c9c9c9;
}

.customInput::before {
  content: "";
  height: 3px;
  width: 0;
  position: absolute;
  bottom: -2px;
  left: 0;
  background-color: #f91c1c;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.screen {
  width: 100vw;
  min-height: 100vh;
}

.tag {
  padding: 0 7px;
  border-radius: 100vw;
  text-transform: capitalize;
}

.ready,
.new,
.Future {
  color: #8f6e0a;
  background-color: #fff0c3;
}

.finished,
.offline,
.Closed {
  color: #5c0a0a;
  background-color: #fed5d5;
}

.active,
.online,
.Out {
  color: #0f6835;
  background-color: #d2fde4;
}

td {
  margin: 10px;
}

@media (max-width: 540px) {
  .auth {
    padding: 15px;
  }
  .uploadPage {
    text-align: center;
  }
  .uploadPage .uploadZone {
    padding: 15px;
    margin: 15px;
  }
  .imageList {
    margin: 15px;
  }
  .imageList .imageContainer {
    width: 100%;
  }
  .nav .menuToggle {
    display: block;
    all: unset;
    height: 50px;
    width: 50px;
  }
  .nav .menuToggle span {
    height: 3px;
    width: 30px;
    background-color: #f91c1c;
    display: block;
    position: relative;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .nav .menuToggle span::before,
  .nav .menuToggle span::after {
    content: "";
    position: absolute;
    top: -9px;
    left: 0;
    height: inherit;
    width: inherit;
    background-color: inherit;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .nav .menuToggle span::after {
    top: 9px;
  }
  .nav .close span {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .nav .close span::after,
  .nav .close span::before {
    top: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .nav .links {
    position: absolute;
    top: 70px;
    left: -100%;
    height: calc(100vh - 70px);
    width: 75%;
    background: #121212;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .nav .links::before {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    width: 34%;
    background: #00000047;
  }
  .nav .opened {
    left: 0;
  }
  .sessions {
    margin: 10px;
  }
  .sessions .sessionsHeader {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.player-index {
  padding: 0 8px;
}
